import React, { useState, useEffect} from 'react'
import logoWhite from './media/logo-white.png'
import logoBlack from './media/logo-black.png'
import happyBirthdayScreen from './media/happy-birthday-screen.png'
import trackBirthdaysScreen from './media/trackbirthdays-screen.png'
import collectVideoScreen from './media/collect-video-screen.png'
import settingsScreen from './media/settings-screen.png'
import cakegifts from './media/cake-gifts.png'
import unicorn from './media/unicorn.png'
import coworkers from './media/coworkers.png'
import addtoslackImg from './media/addtoslack.png'
import gtag from './gtag'
import './App.scss';

const addToSlackURL = 'https://app.cakeday.team/auth/add-to-slack'

function handleAddToSlackClick(e) {
  gtag('js', new Date())
  gtag('config', 'AW-653780307')
  gtag('event', 'conversion', {
    'send_to': 'AW-653780307/bY_zCK7_kNABENPK37cC',
  });
  window.fbq('track', 'StartTrial', {value: '0.00', currency: 'USD', predicted_ltv: '0.00'})
  // window.twq('track', 'AddToSlack');
  window.twttr.conversion.trackPid('o3uve', { tw_sale_amount: 0, tw_order_quantity: 0 })
}

function App() {
  const trackBirthdayFeature = {
    headerImage: cakegifts,
    headline: `Don't lift a finger. Let Cakeday track birthdays for you.`,
    subline: `Running on auto-pilot, Cakeday gathers your team's birthdays and notifies everyone when they come up.`,
    preview: trackBirthdaysScreen,
    backgroundColor: '#fff',
    headlineColor: '#313131',
    sublineColor: '#595959',
    // wave: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ff6c8a" fill-opacity="1" d="M0,96L20,117.3C40,139,80,181,120,202.7C160,224,200,224,240,218.7C280,213,320,203,360,202.7C400,203,440,213,480,202.7C520,192,560,160,600,128C640,96,680,64,720,74.7C760,85,800,139,840,138.7C880,139,920,85,960,69.3C1000,53,1040,75,1080,90.7C1120,107,1160,117,1200,122.7C1240,128,1280,128,1320,128C1360,128,1400,128,1420,128L1440,128L1440,0L1420,0C1400,0,1360,0,1320,0C1280,0,1240,0,1200,0C1160,0,1120,0,1080,0C1040,0,1000,0,960,0C920,0,880,0,840,0C800,0,760,0,720,0C680,0,640,0,600,0C560,0,520,0,480,0C440,0,400,0,360,0C320,0,280,0,240,0C200,0,160,0,120,0C80,0,40,0,20,0L0,0Z"></path></svg>)
  }
  const videoFeature = {
    headerImage: unicorn,
    headline: 'Foster an environment where every individual on your team feels appreciated',
    subline: 'Cakeday autonomously works with your team to create a personalized video for every birthday.',
    preview: collectVideoScreen,
    backgroundColor: '#29c5ff',
    headlineColor: '#e8f9ff',
    sublineColor: '#d4f4ff',
    // wave: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#29c5ff" fill-opacity="1" d="M0,96L20,117.3C40,139,80,181,120,202.7C160,224,200,224,240,218.7C280,213,320,203,360,202.7C400,203,440,213,480,202.7C520,192,560,160,600,128C640,96,680,64,720,74.7C760,85,800,139,840,138.7C880,139,920,85,960,69.3C1000,53,1040,75,1080,90.7C1120,107,1160,117,1200,122.7C1240,128,1280,128,1320,128C1360,128,1400,128,1420,128L1440,128L1440,0L1420,0C1400,0,1360,0,1320,0C1280,0,1240,0,1200,0C1160,0,1120,0,1080,0C1040,0,1000,0,960,0C920,0,880,0,840,0C800,0,760,0,720,0C680,0,640,0,600,0C560,0,520,0,480,0C440,0,400,0,360,0C320,0,280,0,240,0C200,0,160,0,120,0C80,0,40,0,20,0L0,0Z"></path></svg>)
    // headlineColor: '#fff5e6',
    // sublineColor: '#faf4eb'
  }
  const settingsFeature = {
    headerImage: coworkers,
    headline: 'Customize it to fit your schedule',
    subline: 'Cakeday avoids messaging your team on weekends and non-work hours.',
    preview: settingsScreen,
    backgroundColor: '#ff6c8a',
    headlineColor: '#ffebef',
    sublineColor: '#ffe0e7',
    wave: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fillOpacity="1" d="M0,160L13.3,154.7C26.7,149,53,139,80,144C106.7,149,133,171,160,165.3C186.7,160,213,128,240,138.7C266.7,149,293,203,320,234.7C346.7,267,373,277,400,288C426.7,299,453,309,480,288C506.7,267,533,213,560,176C586.7,139,613,117,640,101.3C666.7,85,693,75,720,90.7C746.7,107,773,149,800,154.7C826.7,160,853,128,880,101.3C906.7,75,933,53,960,64C986.7,75,1013,117,1040,128C1066.7,139,1093,117,1120,106.7C1146.7,96,1173,96,1200,122.7C1226.7,149,1253,203,1280,234.7C1306.7,267,1333,277,1360,277.3C1386.7,277,1413,267,1427,261.3L1440,256L1440,320L1426.7,320C1413.3,320,1387,320,1360,320C1333.3,320,1307,320,1280,320C1253.3,320,1227,320,1200,320C1173.3,320,1147,320,1120,320C1093.3,320,1067,320,1040,320C1013.3,320,987,320,960,320C933.3,320,907,320,880,320C853.3,320,827,320,800,320C773.3,320,747,320,720,320C693.3,320,667,320,640,320C613.3,320,587,320,560,320C533.3,320,507,320,480,320C453.3,320,427,320,400,320C373.3,320,347,320,320,320C293.3,320,267,320,240,320C213.3,320,187,320,160,320C133.3,320,107,320,80,320C53.3,320,27,320,13,320L0,320Z"></path></svg>)
    // wave: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#29c5ff" fill-opacity="1" d="M0,96L20,117.3C40,139,80,181,120,202.7C160,224,200,224,240,218.7C280,213,320,203,360,202.7C400,203,440,213,480,202.7C520,192,560,160,600,128C640,96,680,64,720,74.7C760,85,800,139,840,138.7C880,139,920,85,960,69.3C1000,53,1040,75,1080,90.7C1120,107,1160,117,1200,122.7C1240,128,1280,128,1320,128C1360,128,1400,128,1420,128L1440,128L1440,0L1420,0C1400,0,1360,0,1320,0C1280,0,1240,0,1200,0C1160,0,1120,0,1080,0C1040,0,1000,0,960,0C920,0,880,0,840,0C800,0,760,0,720,0C680,0,640,0,600,0C560,0,520,0,480,0C440,0,400,0,360,0C320,0,280,0,240,0C200,0,160,0,120,0C80,0,40,0,20,0L0,0Z"></path></svg>)
    // wave: (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ff6c8a" fill-opacity="1" d="M0,96L20,117.3C40,139,80,181,120,202.7C160,224,200,224,240,218.7C280,213,320,203,360,202.7C400,203,440,213,480,202.7C520,192,560,160,600,128C640,96,680,64,720,74.7C760,85,800,139,840,138.7C880,139,920,85,960,69.3C1000,53,1040,75,1080,90.7C1120,107,1160,117,1200,122.7C1240,128,1280,128,1320,128C1360,128,1400,128,1420,128L1440,128L1440,320L1420,320C1400,320,1360,320,1320,320C1280,320,1240,320,1200,320C1160,320,1120,320,1080,320C1040,320,1000,320,960,320C920,320,880,320,840,320C800,320,760,320,720,320C680,320,640,320,600,320C560,320,520,320,480,320C440,320,400,320,360,320C320,320,280,320,240,320C200,320,160,320,120,320C80,320,40,320,20,320L0,320Z"></path></svg>)
  }

  return (
    <div className="App">
      <div className="bg-pattern"></div>
      <Nav />
      <Hero />
      <div className="Features-container">
        <Feature feature={trackBirthdayFeature} />
        <Feature feature={videoFeature} />
        <Feature feature={settingsFeature} />
      </div>
      <footer>
        <div className="company">
          <img className="logo" src={logoBlack} />
          <div className="copyright">
            © 2020. All Rights Reserved.
          </div>
        </div>
        <div className="Footer-content">
          <div className="Footer-section">
            <div className="Footer-section-header">Help</div>
            <div className="category-link"><a href="mailto:support@cakeday.team">Support</a></div>
            <div className="category-link"><a href="/help" target="_blank">Help Center</a></div>
            <div className="category-link"><a href="https://twitter.com/ohtX">Twitter</a></div>
          </div>
          <div className="Footer-section">
            <div className="Footer-section-header">Legal</div>
            <div className="category-link"><a href="/privacy" target="_blank">Privacy Policy</a></div>
            <div className="category-link"><a href="/terms" target="_blank">Terms of Use</a></div>
          </div>
          <div className="Footer-section Footer-section-about">
            <div className="Footer-section-header">
              Story
            </div>
            <div className="story">
              If you're reading this — Hi, I'm glad you found my work. I'm a solo indie developer based in Los Angeles, CA and I make a living creating software like Cakeday. Thanks to my previous job you've almost certainly used something I've worked on :)
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

function Feature(props) {
  const feature = props.feature
  return (
    <div id="features" className="Features" style={{ backgroundColor: feature.backgroundColor }}>
      <div className="Features-content-container">
        <div className="Features-content">
          <div className="Features-header">
            <img className="Features-header-image" src={feature.headerImage} />
            <div className="Features-headline" style={{ color: feature.headlineColor }}>
              {feature.headline}
            </div>
            <div className="Features-subline" style={{ color: feature.sublineColor }}>
              {feature.subline}
            </div>
          </div>
          <div className="Features-preview">
            <img src={feature.preview} />
          </div>
        </div>
      </div>
      {feature.wave && (
        <div className="wave">
          {feature.wave}
        </div>
      )}
    </div>
  )
}

function Hero() {
  return (
    <div className="Hero">
      <div className="Hero-content-container">
        <div className="Hero-content">
          <div className="Hero-header">
            <div className="Hero-headline">
              Bring your team closer with birthday reminders.
            </div>
            <div className="Hero-subline">
              Cakeday runs on auto-pilot to make every birthday celebration on your team feel special in Slack.
            </div>
            <div className="add-to-slack">
              <a href={addToSlackURL} onClick={handleAddToSlackClick}>
                <img alt="Add to Slack" width="225" src={addtoslackImg} />
              </a>
              <div className="subtext">
                It's free.
              </div>
            </div>

          </div>
          <div className="Hero-product">
            <img src={happyBirthdayScreen} />
          </div>
        </div>
      </div>
      <div className="wave">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fillOpacity="1" d="M0,96L20,117.3C40,139,80,181,120,202.7C160,224,200,224,240,218.7C280,213,320,203,360,202.7C400,203,440,213,480,202.7C520,192,560,160,600,128C640,96,680,64,720,74.7C760,85,800,139,840,138.7C880,139,920,85,960,69.3C1000,53,1040,75,1080,90.7C1120,107,1160,117,1200,122.7C1240,128,1280,128,1320,128C1360,128,1400,128,1420,128L1440,128L1440,320L1420,320C1400,320,1360,320,1320,320C1280,320,1240,320,1200,320C1160,320,1120,320,1080,320C1040,320,1000,320,960,320C920,320,880,320,840,320C800,320,760,320,720,320C680,320,640,320,600,320C560,320,520,320,480,320C440,320,400,320,360,320C320,320,280,320,240,320C200,320,160,320,120,320C80,320,40,320,20,320L0,320Z"></path></svg>
      </div>
    </div>
  )
}

function Nav() {
  const [isNavBurgerActive, setIsNavBurgerActive] = useState(false)
  return (
    <nav className="navbar is-transparent" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src={logoWhite} className="nav-logo"/>
        </a>

        <a role="button" className={`navbar-burger burger ${isNavBurgerActive ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"
        onClick={() => setIsNavBurgerActive(!isNavBurgerActive)}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" className={`navbar-menu ${isNavBurgerActive ? 'is-active' : ''}`}>
        <div className="navbar-start"></div>

        <div className="navbar-end">
          <a className="navbar-item" href="#features">
            Features
          </a>
          <a className="navbar-item" href={addToSlackURL} onClick={handleAddToSlackClick}>
            Add to Slack
          </a>
        </div>

      </div>
    </nav>
  )
}

export default App;
